import { Link } from "gatsby"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <h1>
      <Link to="/">{siteTitle}</Link>
    </h1>
    <nav>
      <ul>
        <li><Link to="/about" activeClassName="active">About</Link></li>
        <li><Link to="/podcast" activeClassName="active">Podcast</Link></li>
        <li><Link to="/resources" activeClassName="active">Resources</Link></li>
        <li><Link to="/events" activeClassName="active">Events</Link></li>
        <li><Link to="/join" activeClassName="active">Join</Link></li>
        <li><Link to="/contact" activeClassName="active">Contact</Link></li>
      </ul>
    </nav>
  </header>
)

export default Header
